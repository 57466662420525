<template>
  <div>
    <div class="d-flex my-1">
      <b-form-checkbox v-model="showPercent">
        {{ $t("showPercent") }}
      </b-form-checkbox>
      <b-form-checkbox v-model="showPercentInBrackets" class="ml-2">
        {{ $t("showPercentInBrackets") }}
      </b-form-checkbox>
    </div>
    <label class="h5">
      {{ $t("ingredients") }}
    </label>
    <b-form-textarea
      v-model="hammaddeler"
      readonly
      rows="6"
      cols="2"
      class="mb-2"
    ></b-form-textarea>
    <label class="h5">
      {{ $t("ingredientDescriptions") }}
    </label>
    <b-form-textarea
      v-model="aciklamalar"
      readonly
      rows="6"
      cols="2"
      class="mb-2"
    ></b-form-textarea>
    <label class="h5">
      {{ $t("nutrients") }}
    </label>
    <b-form-textarea
      v-model="besinmaddeleri"
      readonly
      rows="6"
      cols="2"
      class="mb-2"
    ></b-form-textarea>
  </div>
</template>

<script>
import { BFormTextarea, BFormCheckbox } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BFormTextarea,
    BFormCheckbox,
  },

  props: {
    tags: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showPercent: false,
      showPercentInBrackets: false,
    };
  },
  computed: {
    hammaddeler() {
      return (
        this.tags?.hammaddeler
          ?.map((x) => {
            let str = x.ingredientname;
            const total = this.tags.hammaddeler.reduce(
              (a, b) => a + b.karisim,
              0
            );
            if (this.showPercent && this.showPercentInBrackets)
              str += " (" + (x.karisim / total) * 100 + "%)";
            else if (this.showPercent && !this.showPercentInBrackets)
              str += " " + (x.karisim / total) * 100 + "%";
            return str;
          })
          .join(" ,") ?? ""
      );
    },
    aciklamalar() {
      return (
        this.tags?.hammaddeler?.reduce((a, b) => a + b.info + " ", "") ?? ""
      );
    },
    besinmaddeleri() {
      return (
        this.tags?.besinmaddeleri
          ?.map((x) => {
            let str = x.nutrientname;
            str +=
              " " +
              x.deger.toFixed(getUserData().nutrientdecimal) +
              " " +
              x.birimadi;
            return str;
          })
          .join(",\n") ?? ""
      );
    },
  },
};
</script>

<style></style>
