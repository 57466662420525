import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import { getUserData } from "@/auth/utils";

export default function useProductionFormulasList() {
  // Use toast
  const toast = useToast();

  const refProductionFormulaListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "preview", label: VueI18n.t("view") },
    { key: "rationname", label: VueI18n.t("rationName") },
    {
      key: "kaynak",
      label: VueI18n.t("source"),
      sortable: false,
    },
    {
      key: "rasyonfiyati",
      label: VueI18n.t("price"),
      formatter: (val) =>
        parseFloat(val).toFixed(getUserData().pricedecimal) +
        " " +
        getUserData().currency,
      tdClass: "text-right mw-300",
      sortable: false,
    },
    {
      key: "tarih",
      label: VueI18n.t("date"),
      formatter: (val) =>
        `${dayjs(val)
          .locale(`${VueI18n.locale}`)
          .format("DD MMMM YYYY HH:mm:ss")}`,

      sortable: false,
    },
    {
      key: "tag",
      label: VueI18n.t("tag"),
      sortable: false,
    },

    // { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalProductionFormulas = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProductionFormulaListTable.value
      ? refProductionFormulaListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProductionFormulas.value,
    };
  });

  const refetchData = () => {
    refProductionFormulaListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchProductionFormulas = async (ctx, callback) => {
    var orders = await store
      .dispatch("formulasModule/fetchProductionFormulas", {
        tur: router.currentRoute.params.tur,
        plantid: store.state.app.selectedPlantId,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("formulas") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    return orders;
  };

  const deleteProductionFormula = async (ctx) => {
    await store.dispatch("formulasModule/deleteProductionFormula", {
      formulaid: ctx.id,
      plantid: ctx.plantid,
      tur: ctx.tur,
    });
    refetchData();
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchProductionFormulas,
    tableColumns,
    perPage,
    currentPage,
    totalProductionFormulas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductionFormulaListTable,
    deleteProductionFormula,
    statusFilter,

    refetchData,
  };
}
