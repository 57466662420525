<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>adet</label> -->
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductionFormulaListTable"
        class="position-relative"
        :items="fetchProductionFormulas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(tag)="{ item }">
          <b-link v-b-modal.tagModal @click="openTagModal(item.rasyonid)">
            {{ $t("showTag") }}
          </b-link>
        </template>
        <!-- Column: ProductionFormula -->

        <template #cell(preview)="data">
          <b-link
            :to="{
              name: 'productionFormulaView',
              params: {
                formulaid: data.item.rasyonid,
                id: $store.state.app.selectedPlantId,
              },
            }"
          >
            <feather-icon icon="EyeIcon" />
            <small class="align-middle ml-50 text-dark">{{ $t("view") }}</small>
          </b-link>
        </template>
        <!-- <template #cell(delete)="data">
          <b-link
            class="text-danger cursor-pointer"
            @click="
              deleteProductionFormula({
                id: data.item.rasyonid,
                plantid: $store.state.app.selectedPlantId,
                tur: $route.params.tur,
              })
            "
          >
            <feather-icon class="text-danger" icon="Trash2Icon" />
            <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
              $t("delete")
            }}</small>
          </b-link>
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductionFormulas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="tagModal"
      scrollable
      :title="$t('tag')"
      :ok-title="$t('ok')"
      ok-only
    >
      <production-formula-tags
        :key="tagDetail[0].rasyon[0].rasyonid"
        :tags="tagDetail[0]"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BModal,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useProductionFormulaList from "./useProductionFormulaList";
import VueI18n from "@/libs/i18n";
import ProductionFormulaTags from "./Tags/Tags.vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BModal,
    ProductionFormulaTags,
    // vSelect,
  },

  beforeRouteEnter(to, from, next) {
    if (to.name == "productionFormulas") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/",
        text: VueI18n.t("formulas"),
        active: true,
      });

      to.meta.breadcrumb.push({
        text: "productionFormulas",
        active: true,
      });
    }
    next();
  },

  setup({ emit }) {
    const selectedProductionFormula = ref({});
    const tagDetail = ref([{ rasyon: [{ rasyonid: 0 }] }]);
    const {
      fetchProductionFormulas,
      tableColumns,
      perPage,
      currentPage,
      totalProductionFormulas,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductionFormulaListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteProductionFormula,
    } = useProductionFormulaList();

    const openTagModal = async (id) => {
      tagDetail.value = await store.dispatch(
        "formulasModule/fetchProductionRationTagDetails",
        {
          id: id,
        }
      );
    };

    return {
      openTagModal,
      tagDetail,
      // Sidebar
      selectedProductionFormula,
      fetchProductionFormulas,
      tableColumns,
      perPage,
      currentPage,
      totalProductionFormulas,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductionFormulaListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteProductionFormula,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mw-300 {
  width: 150px;
  max-width: 150px;
}
</style>
