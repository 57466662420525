var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}}),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1 mw-33",attrs:{"placeholder":_vm.$t('search')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),_c('b-table',{ref:"refProductionFormulaListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchProductionFormulas,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":_vm.$t('noRecords'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(tag)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.tagModal",modifiers:{"tagModal":true}}],on:{"click":function($event){return _vm.openTagModal(item.rasyonid)}}},[_vm._v(" "+_vm._s(_vm.$t("showTag"))+" ")])]}},{key:"cell(preview)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'productionFormulaView',
            params: {
              formulaid: data.item.rasyonid,
              id: _vm.$store.state.app.selectedPlantId,
            },
          }}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('small',{staticClass:"align-middle ml-50 text-dark"},[_vm._v(_vm._s(_vm.$t("view")))])],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("showing"))+" "+_vm._s(_vm.dataMeta.from)+" "+_vm._s(_vm.$t("to"))+" "+_vm._s(_vm.dataMeta.to)+" "+_vm._s(_vm.$t("of"))+" "+_vm._s(_vm.dataMeta.of)+" "+_vm._s(_vm.$t("entries"))+" ")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalProductionFormulas,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"tagModal","scrollable":"","title":_vm.$t('tag'),"ok-title":_vm.$t('ok'),"ok-only":""}},[_c('production-formula-tags',{key:_vm.tagDetail[0].rasyon[0].rasyonid,attrs:{"tags":_vm.tagDetail[0]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }